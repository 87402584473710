.desking-sheet {
  position: absolute;
  top: -5000px;
}

.desking-sheet p {
  margin: 0;
}

.desking-sheet .k-grid-table-wrap {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
}

.desking-sheet .pdf-title {
  color: var(--inchcape-dark-blue);
  font-family: "Poppins Bold";
  font-size: 15px;
  margin-bottom: 10px;
}

.desking-sheet .pdf-header-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  row-gap: 54px;
  column-gap: 10px;
  margin-bottom: 8px;
}

.desking-sheet .pdf-header-container-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1px;
}

.desking-sheet .pdf-header-container-label,
.pdf-title-common {
  font-family: "Poppins Bold";
  font-size: 8px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .pdf-header-container-value {
  font-family: "Poppins";
  font-size: 8px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .k-column-title {
  font-family: "Poppins Bold";
  font-size: 7px;
}

.desking-sheet .k-grid-content {
  overflow: hidden;
}

.desking-sheet .k-cell-inner > .k-link {
  padding: 2px 12px;
}

.desking-sheet .row-item {
  font-size: 7px;
  font-family: "Poppins";
  color: var(--inchcape-dark-blue);
}

.desking-sheet .rowCell,
.desking-sheet .pdf-grid-title-detail-footer,
.desking-sheet .pdf-grid-title-existing-footer,
.desking-sheet .pdf-grid-title-detail-footer {
  padding: 2px 8px;
}

.desking-sheet .proposed-row-item {
  font-family: "Poppins";
  color: var(--inchcape-blue);
  font-size: 7px;
}

.desking-sheet .expanded-details-row {
  font-family: "Poppins";
  color: var(--inchcape-dark-blue);
  font-size: 7px;
}

.desking-sheet .monthly-payment-amount {
  font-family: "Poppins Bold";
  font-size: 7px;
  color: var(--inchcape-dark-blue);
}

.desking-sheet .row-highlight-label,
.desking-sheet .row-highlight {
  font-family: "Poppins Bold";
  font-size: 7px;
  color: var(--inchcape-blue);
}

.desking-sheet .header-row-highlight-label {
  font-family: "Poppins Bold";
  font-size: 7px;
  color: var(--inchcape-blue);
  padding: 0;
}

.desking-sheet .pdf-grid-two-col {
  display: flex;
  gap: 10px;
}

.desking-sheet .pdf-grid-vehicle-details,
.desking-sheet .pdf-grid-finance-details,
.desking-sheet .pdf-grid-two-col {
  margin-bottom: 8px;
}

.desking-sheet .footer-grid {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 14.8px);
  margin-bottom: 14px;
}

.desking-sheet .footer-grid div {
  margin: 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
}

.desking-sheet .pdf-grid-title-footer {
  font-family: "Poppins Bold";
  font-size: 7px;
  color: rgba(0, 35, 58, 0.6);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
}
